.sectionOne {
  height: 100vh;
}

.sectionOne .background {
  height: 100%;
  padding-top: 500px;
  background: url(../../images/sources-homepage/A110/Presentation_desktop-1.png);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionOne h1{
 
  font-size: 4em;
 text-align: center;
 padding-bottom: 500px;
  margin: 0;
}

.sectionOne h3 {
  font-size: 20px;
  margin: 0;
}

.sectionOne .card {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px; 
  border: 0;
}

@media screen and (max-width: 768px) {
  .sectionOne .card {
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    border: 0;
  }
  
  .sectionOne h1{
    padding-top: 50px;
    font-size: 60em;
    margin: 0;
  }

  .sectionOne h3 {
    font-size: 20px;
    margin: 0;
  }

  .sectionOne .background {
    
      height: 50vh; /* Ajustez la hauteur selon vos besoins */
      padding-top: 100px; /* Ajustez l'espacement en haut selon vos besoins */
      background-size: auto; /* Ajustez la taille de l'image pour qu'elle s'adapte */
  }
}

@media screen and (max-width: 1400px) {
  .sectionOne .card {
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    border: 0;
  }
  
  .sectionOne h1{
    font-size: 25px;
    margin: 0;
  }

  .sectionOne h3 {
    font-size: 15px;
    margin: 0;
  }

  .sectionOne .background {
    height: 100%;
    padding-top: 350px;
    background: url(../../images/sources-homepage/A110/Presentation_desktop-1.png);
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}