.sectionTwo .videoBackground {
    width: 100%;
    height: 85vh;
    object-fit: cover;
    z-index: -1;
}

@media screen and (max-width: 768px) {

    .sectionTwo .videoBackground {
        width: 100%;
        height: 50vh;
        object-fit: cover;
        z-index: -1;
    }

}