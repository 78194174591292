.sectionVersion {
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    padding-top: 0;
    padding-left: 1%;

}

.sectionVersion h3 {
    padding-top: 1%;
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
}

.button1 {
    height: 10%;
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10%;
    width: 10%;
}

.alpineChange {

    width: 100%;
    height: 100%;
}

.alpineChange1 {
    padding-top: 100px;
    width: 650px;
    height: auto;
}

.alpineChange2 {
    padding-top: 100px;
    width: 650px;
    height: auto;
}

.alpineAffichage {
    display: flex;
    justify-content: center;
    align-items: center;

}

.pictureScellerie {
    height: 250px;
}



@media screen and (max-width: 1550px) {  

    .alpineChange1 {
        padding-top: 50px;
        width: 150px;
        height: auto;
    }
    
    .alpineChange2 {
        padding-top: 50px;
        width: 150px;
        height: auto;
    }

}

@media screen and (max-width: 700px) {  

   .sectionVersion {
    padding-bottom: 0px!important;
   }

   .button1 {
    height: 20%;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.button2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 20%;
    margin-bottom: 20px;
}
  
}