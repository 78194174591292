footer {
    background-color: #000;
    color: #fff;
    padding: 20px 0;
}

.footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-logo {
    max-width: 150px;
    margin-bottom: 20px;
}

.footer-nav {
    display: flex;
    flex-wrap: wrap;
}

.footer-nav a {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
    padding: 5px;
    transition: color 0.3s ease;
}

.footer-nav a:hover {
    color: #1e90ff;
}

.footer-social-media {
    /* Ici, vous pouvez ajouter des icônes pour les réseaux sociaux avec un style approprié */
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
}

@media screen and (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }

    .footer-nav {
        justify-content: center;
    }

    .footer-logo,
    .footer-social-media,
    .footer-bottom {
        margin-bottom: 20px;
    }
}
