.summary-card {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    color: white;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    background-image: url(../../images/bg/Capture\ d\'écran\ 2024-04-23\ 114452.png);

  }
  