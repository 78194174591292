* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.img-accueil {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}