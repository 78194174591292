.sectionInterieur {
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;

}

.pictureScellerie {
    display: flex;
    width: auto;
    height: 400px;
   padding-bottom: 20px;
    margin: 0;
    justify-content: center;
}

.sectionInterieur button img {
    border: transparent;
    height: 7em;
    width: 10em;

}

.sectionInterieur h3{
    display: flex;
    justify-content: center;
}



@media screen and (max-width: 700px) {

    .pictureScellerie {
        display: flex;
        width: auto;
        
        padding-top: 100px;
        margin: 0;
        flex-direction: column-reverse;
        
    }
    
    .sectionScellerie button img {
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4em;
        width: 6em;
      
    }

    .card-body {

    padding-top: 200px;
    }

}
